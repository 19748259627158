import { Component, TemplateRef, ViewChild } from '@angular/core';
import { WorkerMiddlewareService } from '../_Services/common/worker.service';
import { Router, RouterEvent, RouteConfigLoadStart, RouteConfigLoadEnd, ActivatedRoute, NavigationEnd } from "@angular/router";
import { NetworkCheckService } from '../components/common/network-check';
import { UtilityService } from '../_Services/common/utility.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { EntityAccessList } from '../components/common/static-data-common';
import { UserAccountService } from '../_Services/UserAccount/useraccount.service';

@Component({
	selector: 'app-main-layout',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.scss']
})
export class MainComponent {
	title = 'APILINK ';
	allowScrollTop: boolean;
	showScrollTop: boolean;
	public isShowingRouteLoadIndicator: boolean;
	public _IsOnline: boolean;
	@ViewChild('switchEntityPopup') switchEntityContent: TemplateRef<any>;
	entityChangeMessage: string;
	isSwitchEntity: boolean; // based on this boolean text will be changed to in html
	modalRef: NgbModalRef;
	pathEntity: string;
	boundStorageEventListener: any;
	//public networkservice: NetworkCheckService
	constructor(workerService: WorkerMiddlewareService, private router: Router, networkservice: NetworkCheckService, private utilityService: UtilityService,
		public modalService: NgbModal, private activatedRoute: ActivatedRoute, private translate: TranslateService, private userAccountService: UserAccountService) {
		var asyncLoadCount = 0;
		this.isShowingRouteLoadIndicator = false;
		workerService.init();
		this.allowScrollTop = true;
		this.showScrollTop = false;

		//to show loading while navigate between routes at first time
		router.events.subscribe(
			(event: RouterEvent): void => {
				if (event instanceof RouteConfigLoadStart) {
					networkservice.createOnline$().subscribe(x => this._IsOnline = x);
					if (!this._IsOnline)
						router.navigate(['/error/0']);
					else
						asyncLoadCount++;

				} else if (event instanceof RouteConfigLoadEnd) {

					asyncLoadCount--;

				}
				this.isShowingRouteLoadIndicator = !!asyncLoadCount;
			}
		);

		this.boundStorageEventListener = this.onEntityChangedAcrossTabs.bind(this);
	}
	ngOnInit() {
		window.addEventListener('scroll', e => {
			let top = window.pageYOffset || document.documentElement.scrollTop;

			if (top > 200) {
				this.showScrollTop = true;
			} else {
				this.showScrollTop = false;
			}
		});

		//bind the storage change event
		window.addEventListener('storage', this.boundStorageEventListener);

		this.pathEntity = this.activatedRoute.firstChild.snapshot.paramMap.get('entity');		
	}

	ngOnDestroy() {
		// Clean up listener when component is destroyed
		window.removeEventListener('storage', this.boundStorageEventListener);		
	}

	ngAfterViewInit() {
		this.verifyEntity();
	}

	scrollToTop() {
		window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
	}

	async verifyEntity() {


		var currentEntityId = Number(this.utilityService.getEntityId());

		var pathEntityId = EntityAccessList.find(x => x.name.toLowerCase() == this.pathEntity.toLowerCase())?.id;

		let buttonMsg = '';
		this.translate.get("LOGIN.MSG_SWTICH_BUTTON").subscribe((text: string) => { buttonMsg = text });
		
		//if the pathEntity and current selected entity doesn't match then need to show popup
		if (pathEntityId && pathEntityId !== currentEntityId) {
			//if user is accsssible the path entity or not.
			let result = await this.userAccountService.isEntityAccesible(pathEntityId);
			let message = '';
			if (result) {
				this.translate.get("LOGIN.MSG_ENTITY_CHANGE").subscribe((text: string) => { message = text });
				message = message.replace('{0}', this.pathEntity).replace('{1}', `${this.utilityService.getEntityName()}`);
			}
			else {
				this.translate.get("LOGIN.MSG_ENTITY_NOACCESS").subscribe((text: string) => { message = text });
				message = message.replace('{0}', this.pathEntity);
			}
			this.entityChangeMessage = message + ", " + buttonMsg;
			this.isSwitchEntity = false;
			this.modalService.open(this.switchEntityContent, { centered: true, backdrop: 'static', size: 'lg' })
		}
		//if user enter manually wrong entity name then we need to show meessage wrong entity
		else if (pathEntityId == null || pathEntityId == undefined) {
			let message = '';
			this.translate.get("LOGIN.MSG_ENTITY_NOTFOUND").subscribe((text: string) => { message = text });
			this.entityChangeMessage = message.replace('{0}', this.pathEntity) + ", " + buttonMsg;
			this.isSwitchEntity = false;
			this.modalService.open(this.switchEntityContent, { centered: true, backdrop: 'static', size: 'lg' })
		}
	}

	//this function will be triggered when the storage value will be changed
	//from this function we are checking the entity changed or not
	onEntityChangedAcrossTabs(event: StorageEvent) {
		if (event.key === '_entityId') {
			//if the old value and new value different -> in this place always old value and new vaule will not same
			if (event.oldValue != event.newValue) {
				
				let switchedEntityName = this.utilityService.getEntityName();
				let message = '';
				this.translate.get("LOGIN.MSG_SWITCH_ENTITY").subscribe((text: string) => { message = text });
				this.entityChangeMessage = message.replace('{0}', switchedEntityName);

				//if the modal hase already open then we need to close the popup and again check entity
				if (this.modalRef && this.modalRef != null) {
					//fetch the path entity					
					var currentEntityId = Number(this.utilityService.getEntityId());
					var pathEntityId = EntityAccessList.find(x => x.name.toLowerCase() == this.pathEntity.toLowerCase())?.id;

					//close modal
					this.modalRef.close();
					this.modalRef = null;
					//if the path entity and current entity will be defer 
					//this scenario for the if the user first selected api entity then change to sgt entity then pop will be apear and after user again select the api entity then we need to close the popup
					//that reason we are checking the path entity and current entity is same or not.
					if (pathEntityId !== currentEntityId) {
						this.isSwitchEntity = true;
						this.modalRef = this.modalService.open(this.switchEntityContent, { centered: true, backdrop: 'static', size: 'lg' });
					}
				}
				else {
					this.isSwitchEntity = true;
					this.modalRef = this.modalService.open(this.switchEntityContent, { centered: true, backdrop: 'static', size: 'lg' });
				}
			}
		}
	}

	navigateToLanding() {
		this.router.navigate(["/landing"]);
	}
}
