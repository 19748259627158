import { Component, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JsonHelper } from './jsonHelper';
import { NewOption } from '../common/static-data-common'
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class Validator {

  private requiredArr: Array<any>;
  private jsonObject: any;
  private jsonPath: string;
  private model: any;
  private getModelFunc: any;
  public isSubmitted = false;
  private Newoption = NewOption
  private isSetModelAsync = false;
  public isDebug = false;
  private static firstToaster: any = "";

  constructor(public jsonHelper: JsonHelper, private toastr: ToastrService, private translate: TranslateService) {
    this.requiredArr = [];
  }


  public static getValidator(model: any, jsonpath: string, jsonHelper: JsonHelper, isSubmitted, toastr: ToastrService, translate: TranslateService): Validator {
    let newValidator = new Validator(jsonHelper, toastr, translate);
    newValidator.setJSON(jsonpath);
    newValidator.setModel(model);
    newValidator.isSubmitted = isSubmitted;

    return newValidator;
  }

  public setJSON(jsonpath: string) {
    this.jsonPath = "validator/" + jsonpath;

    this.jsonHelper.getJSON(this.jsonPath).subscribe(data => {
      this.jsonObject = data;
    });
  }

  public setJSONObject(object: any) {
    this.jsonPath = "";
    this.jsonObject = object;
  }



  private setValidElement(fieldname, isValidFunc) {

    var data = this.requiredArr.filter(x => x.name == fieldname);

    if (data.length == 0)
      this.requiredArr.push({ name: fieldname, isValid: isValidFunc });
    else
      data[0].isValid = isValidFunc;
  }

  public setModel(model: any) {
    this.model = model;
  }

  public setModelAsync(func: any) {
    this.isSetModelAsync = true;
    this.getModelFunc = func;
  }

  private initModel() {
    if (this.isSetModelAsync) {
      this.model = this.getModelFunc();
    }
  }

  private getField(fieldName) {
    if (this.jsonObject == null) {
      return null;
    }
    return this.jsonObject.fields[fieldName];
  }


  private getModelField(fieldName) {

    this.initModel();

    if (this.model == null) {
      return null;
    }

    this.debug(fieldName);
    return this.model[fieldName];
  }

  public initTost() {
    Validator.firstToaster = "";
  }

  public isValid(fieldName, showToaster: boolean = true): boolean {
    this.debug(fieldName);
    this.setValidElement(fieldName, (filename) => this.isValid(filename));

    if (!this.isSubmitted)
      return true;

    var errors = this.getErrors(fieldName);
    // console.log("firstToaster:" + Validator.firstToaster);
    if (Validator.firstToaster == "") {

      if (errors.length > 0 && showToaster) {
        Validator.firstToaster = fieldName;
        for (let error of errors) {
          let tradMessage: string = "";
          this.translate.get(error).subscribe((text: string) => { tradMessage = text });
          this.toastr.warning(tradMessage, "Validation error");
        }

      }
    }


    return errors.length == 0;
  }

  public isValidIf(fieldName, flag): boolean {
    this.initModel();
    this.setValidElement(fieldName, (filename) => this.isValidIf(filename, flag));

    if (!this.isSubmitted)
      return true;

    var errors = this.getErrorsIf(fieldName, flag);

    return errors.length == 0;

  }

  public isValidIfAsync(fieldName, flagfunc, arg: any = null): boolean {
    this.initModel();
    this.setValidElement(fieldName, (filename) => this.isValidIfAsync(filename, flagfunc, arg));

    if (!this.isSubmitted)
      return true;

    var errors = this.getErrorsIf(fieldName, flagfunc(this.model, arg));

    return errors.length == 0;

  }

  //public isValidCompare(fieldName, fieldValue): boolean {

  //  this.setValidElement(fieldName, (filename) => this.isValidCompare(filename, fieldValue));

  //  if (!this.isSubmitted)
  //    return true;

  //  console.log("is valid compare");
  //  console.log("fieldValue:" + fieldValue);
  //  var errors = this.getErrorsCompare(fieldName, fieldValue); 
  //  console.log("errors:" + errors.length);

  //  return errors.length == 0;
  //}

  //public getErrorsCompare(fieldName, fieldValue) {

  //  let field = this.getField(fieldName);

  //  let errors = [];

  //  if (field == null)
  //    return errors;

  //  for (let rule of field) {
  //    var error = this.getRuleError(fieldName, rule, fieldValue);

  //    if (error != null)
  //      errors.push(error);
  //  }

  //  return errors;
  //}


  private getRuleError(fieldName, rule: any): string {

    let fieldModel = this.getModelField(fieldName);

    this.debug("fieldName:" + fieldName);
    this.debug("fieldModel:" + fieldModel);

    if (rule.type == 'required') {
      let fieldType = rule.fieldType;

      if (fieldType == null)
        fieldType = 'T';

      if (this.required(fieldModel, fieldType, rule)) {

        return rule.ressource;
      }
    }
    else if (rule.type == 'email') {
      if (this.email(fieldModel))
        return rule.ressource;
    }
    else if (rule.type == 'date') {
      if (this.isDate(fieldModel)) {
        return rule.ressource;
      }
    }
    else if (rule.type == 'number') {
      if (this.number(fieldModel)) {
        return rule.ressource
      }
    }
    else if (rule.type == 'isLessOrEqual') {
      let fieldType = rule.fieldType;
      let fieldCible = rule.fieldCible;
      if (fieldCible == null)
        return null;

      let fieldValue = this.getModelField(fieldCible);
      if (fieldValue != null && fieldModel != null && fieldType != null && this.isLessOrEqual(fieldModel, fieldValue, fieldType))
        return rule.ressource;
    }
    else if (rule.type == 'isGreatOrEqual') {
      let fieldType = rule.fieldType;
      let fieldCible = rule.fieldCible;

      if (fieldCible == null)
        return null;

      let fieldValue = this.getModelField(fieldCible);

      if (fieldValue != null && fieldModel != null && fieldType != null && this.isGreatOrEqual(fieldModel, fieldValue, fieldType))
        return rule.ressource;
    }

    return null;
  }

  public getErrors(fieldName) {

    let field = this.getField(fieldName);

    let errors = [];

    if (field == null)
      return errors;

    for (let rule of field) {
      let error = this.getRuleError(fieldName, rule);
      if (error != null)
        errors.push(error);
    }
    return errors;
  }

  public getErrorsIf(fieldName, flag) {

    let field = this.getField(fieldName);
    let fieldModel = this.getModelField(fieldName);

    let errors = [];

    if (field == null)
      return errors;

    for (let rule of field) {
      if (rule.type == 'required') {
        let fieldType = rule.fieldType;

        if (flag != null && fieldType != null && this.requiredIf(fieldModel, fieldType, flag))
          errors.push(rule.ressource);
      }
      else if (rule.type == 'email') {
        if (flag != null && this.emailIf(fieldModel, flag))
          errors.push(rule.ressource);
      }
      else if (rule.type == 'number') {
        if (flag != null && this.numberIf(fieldModel, flag))
          errors.push(rule.ressource);
      }
      else if (rule.type == 'isLessOrEqual') {
        let fieldType = rule.fieldType;
        let fieldCible = rule.fieldCible;
        if (fieldCible == null)
          return null;

        let fieldValue = this.getModelField(fieldCible);
        if (flag != null && fieldValue != null && fieldModel != null && fieldType != null && this.isLessOrEqualIf(fieldModel, fieldValue, fieldType, flag))
          errors.push(rule.ressource);
      }
      else if (rule.type == 'isGreatOrEqual') {
        let fieldType = rule.fieldType;
        let fieldCible = rule.fieldCible;

        if (fieldCible == null)
          return null;

        let fieldValue = this.getModelField(fieldCible);

        if (flag != null && fieldValue != null && fieldModel != null && fieldType != null && this.isGreatOrEqualIf(fieldModel, fieldValue, fieldType, flag))
          errors.push(rule.ressource);
      }
    }

    return errors;
  }

  public required(value, type, rule: any) {
    if (type == "NP")
      return value == null || (value <= 0);
    if (type == "N")
      return value == null || (value <= 0 && value != this.Newoption);
    if (type == "NU")//it will allow zero value
      return value == null;

    if (type == "T")
      return value == null || value.toString().trim() == ""

    if (type == "D")
      return value == null || value.toString().trim() == "";

    if (type == "A")
      return value == null || value.length <= 0;

    if (type == "AT") {

      if (value == null || value.length <= 0)
        return true;

      if (rule != null && rule.fields != null && rule.fields.length > 0) {
        for (let item of rule.fields) {
          for (let itemvalue of value) {
            let subitem = item.name;
            let subValue = itemvalue[subitem];
            let subFieldType = item.fieldType;

            let xx = this.required(subValue, subFieldType, null);

            if (!xx)
              return false;
          }
        }
        return true;
      }

      return false;
    }

    return true;
  }

  public requiredIf(value, type, flag) {
    if (flag)
      return this.required(value, type, null);

    return false;
  }
  public emailIf(value, flag) {
    if (flag)
      return this.email(value);

    return false;
  }
  public email(value) {

    if (value == null || value == "")
      return false;

    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !re.test(String(value).toLowerCase());
  }

  public numberIf(value: any, flag) {
    if (flag)
      return !isNaN(value);
  }

  public number(value: any) {
    return !isNaN(value);
  }

  public isDate(value) {

    if (value == null || value.toString() == "")
      return false;

    return value.year == null || value.month == null || value.day == null;
  }

  public isDecimal(value: any) {

    if (!this.number(value))
      return true;

  }

  private isLessOrEqual(value1: any, value2: any, type) {
    if (type == 'N')
      return value1 > value2;

    if (type == 'D' && !this.isDate(value1) && !this.isDate(value2))
      return value1.year > value2.year || (value1.year == value2.year && value1.month > value2.month) || (value1.year == value2.year && value1.month == value2.month && value1.day > value2.day)
    else
      return false;
  }


  private isGreatOrEqual(value1: any, value2: any, type) {
    if (type == 'N')
      return value1 < value2;

    if (type == 'D' && !this.isDate(value1) && !this.isDate(value2))
      return value1.year < value2.year || (value1.year == value2.year && value1.month < value2.month) || (value1.year == value2.year && value1.month == value2.month && value1.day < value2.day)
    else
      return false;
  }
  private isLessOrEqualIf(value1: any, value2: any, type, flag) {
    if (flag)
      return this.isLessOrEqual(value1, value2, type);
  }


  private isGreatOrEqualIf(value1: any, value2: any, type, flag) {
    if (flag)
      return this.isGreatOrEqual(value1, value2, type);
  }

  public isFormValid() {

    let isValid: boolean = true;
    this.debug(this.requiredArr);
    for (let item of this.requiredArr) {
      if (!item.isValid(item.name)) {
        isValid = false;
        break;
      }
    }

    return isValid;
  }



  private debug(message) {
    if (this.isDebug)
      console.log(message);
  }


}
