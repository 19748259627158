import { Component, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class JsonHelper {

  constructor(private http: HttpClient) {

  }

  public getJSON(file): Observable<any> {

    file = "assets/" + file;
    return this.http.get(file);
  }

}
