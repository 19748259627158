import { NgbModal, ModalDismissReasons, NgbModalConfig, NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Component, Injectable } from '@angular/core';


@Component({
  selector: 'waiting-content',
  template: `
   <img src = "assets/img/loadingImage.gif" />
  `
})
export class WaitingContent {
  constructor(public activeModal: NgbActiveModal) {

  }

}


@Injectable({ providedIn: 'root' })
export class WaitingService {

  private modalRef: NgbModalRef;

  constructor(private modalService: NgbModal, config: NgbModalConfig) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  open() {
    this.modalRef = this.modalService.open(WaitingContent, { centered: true, windowClass: 'custom-waiting' });
  }
  close() {
    this.modalRef.close(); 
  }

}
