<div class="main-wrapper">
  <app-header></app-header>
  <div class="wrapper dashboard-wrapper">
    <!-- <div *ngIf="isShowingRouteLoadIndicator" class="router-load-indicator">
          Loading Module...
        </div> -->
    <div class="loader-overlay" *ngIf="isShowingRouteLoadIndicator && _IsOnline">
      <div class="loader-container">
        <img src="assets/images/reload.svg" alt="">
        <span>{{'COMMON.LBL_LOADER' | translate}}</span>
      </div>
    </div>
    <router-outlet></router-outlet>
  </div>
</div>
<app-drawer-menu></app-drawer-menu>
<div class="scroll-top-container" *ngIf="allowScrollTop && showScrollTop" (click)="scrollToTop()">
  <img src="assets/images/left-arrow.svg">
</div>

<ng-template #switchEntityPopup let-modal>
  <div class="delete-popup-container">
    <div class="content">
      <img src="assets/images/Alert.svg" alt="">
      <div class="title-container">
        <h3>{{'LOGIN.MSG_WRONG_ENTITY' | translate }}</h3>
        <p>
          {{entityChangeMessage}}
        </p>
      </div>
    </div>
    <div class="text-right">
      <button class="secondary-cta" (click)="modal.dismiss('Cross click');navigateToLanding()">{{isSwitchEntity ?
        'Refresh' : 'Switch'}}</button>
    </div>
  </div>
</ng-template>