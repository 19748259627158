
export class summaryModel {
  index: number;
  pageSize: number;
  totalCount: number;
  pageCount: number;
  noFound: boolean;
  items: Array<any>;
  sortByColumnName: string = "";
  isDescending: boolean;
}
