import { Injectable, Inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable, Observer, fromEvent, merge } from 'rxjs';
@Injectable({ providedIn: 'root' })

export class NetworkCheckService {
  createOnline$() {
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      })); 
  }
} 